h
<template>
  <!-- eslint-disable -->

  <div>
    <v-dialog
      overlay-color="munjz-accent-2"
      overlay-opacity="0.7"
      persistent
      v-model="cancelDialog"
      max-width="290"
    >
      <v-card>
        <v-card-text>
          <b> Are you sure that you want to cancel the order ? </b>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="primary darken-1"
            :loading="cancelLoading"
            @click="CancelOrder"
          >
            yes !
          </v-btn>
          <v-btn
            class="me-0"
            color="red darken-1"
            @click="cancelDialog = false"
          >
            no
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      overlay-color="munjz-accent-2"
      overlay-opacity="0.7"
      persistent
      v-model="please_wait_dialog"
      width="300"
    >
      <v-card color="#2196F3" dark>
        <v-card-text class="white--text">
          Please wait ...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container>
      <v-card class="pa-0 rounded-lg">
        <v-row class="munjz-bg-grey ma-0 rounded-t-lg">
          <v-col cols="10" sm="4" class="d-flex align-center me-auto">
            <span>Orders <v-icon>mdi-chevron-right</v-icon></span>
            <span class="text-h4 text-sm-h2 ">Order Information </span>
          </v-col>
          <v-col sm="2" lg="1">
            <div
              class="d-flex justify-center align-center"
              style="min-height: 100%"
            >
              <v-btn to="/orders" class="white mx-2" small fab text>
                <v-icon large color="blue-grey ">mdi-close</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-row class="munjz-bg-grey ma-0 justify-center">
          <v-col lg="2">
            <h4>Order No.</h4>
            <span>#{{ order.order_no }}</span>
          </v-col>

          <v-col>
            <h4>Seller ID</h4>

            <span>
              {{ merchant.name }}
            </span>
          </v-col>

          <v-col>
            <h4>Branch</h4>

            <span>
              {{ branchDetail.name }}
            </span>
          </v-col>

          <v-col>
            <h4>Created Date and Time</h4>
            <span>
              {{ order.created_at }}
            </span>
          </v-col>
        </v-row>

        <!-- <v-row class="red ma-0 ">
            <v-col cols="2" class="ms-auto">
            <div class="float-end">
          <v-chip
          
            dark
            dense
            outlined
            :color="orderStateOptions[order.status].color"
            ><h2> {{ order.status_name }}</h2></v-chip
          >
         </div>
            </v-col>
          </v-row> -->

        <v-row class="ma-0">
          <v-col cols="12" class="py-5">
            <h2>Customer Information</h2>
          </v-col>
          <v-col cols="12" sm="4" class="me-auto">
            <div class="d-flex flex-row align-baseline">
              <v-icon class="mr-2">mdi-account</v-icon>
              <v-label
                ><span style="font-weight: bold"> Name :</span>
                {{ order.customer_name }}
              </v-label>
            </div>
            <div class="d-flex flex-row align-baseline mt-3">
              <v-icon class="mr-2">mdi-phone</v-icon>
              <v-label>
                <span style="font-weight: bold"> Mobile :</span
                >{{ order.customer_phone }}
              </v-label>
            </div>
            <div class="d-flex flex-row align-baseline mt-3">
              <v-icon class="mr-2">mdi-mail</v-icon>
              <v-label>
                <span style="font-weight: bold">E-mail :</span> NA
              </v-label>
            </div>
            <div class="d-flex flex-row align-baseline mt-3">
              <v-icon class="mr-2">mdi-city-variant</v-icon>
              <v-label
                ><span style="font-weight: bold"> Location :</span>
                {{ order.location ? order.location : "N/A" }}
              </v-label>
            </div>
          </v-col>

          <v-col cols="12" sm="5" class=" ">
            <span class="d-flex flex-row">
              <div class="pl-6 d-flex align-center">
                <v-icon large class="mr-2">mdi-calendar</v-icon>
                <v-label>
                  <h3>Delivery Date</h3>
                  <br />
                  <span class=""> {{ order.delivery_date }}</span>
                </v-label>
              </div>

              <div class="d-flex align-center pl-14">
                <v-icon large class="mr-2">mdi-google-maps</v-icon>
                <v-label>
                  <h3 class=" ">Pickup location</h3>
                  <br /><span v-if="order.pickup_option_id == 1">
                    Pickup from store
                  </span>
                  <span class="" v-if="order.pickup_option_id == 2">
                    Delivery to customer
                  </span>
                </v-label>
              </div>
            </span>
          </v-col>
          <v-col cols="12" sm="3" md="2" class="d-flex justify-center ms-auto">
            <div class="d-flex">
              <v-chip
                dark
                dense
                outlined
                :color="orderStateOptions[order.status].color"
                ><h2>{{ order.status_name }}</h2></v-chip
              >
            </div>
          </v-col>
        </v-row>
        <v-row block class="ma-0">
          <v-col cols="12">
            <h2>
              Services{{ hasPermission("View Cost") ? " Cost" : " Details" }}:
            </h2>
            <v-simple-table class="my-4">
              <thead>
                <tr style="color: black" class="munjz-bg-grey text-center">
                  <td>Service type</td>
                  <td v-if="hasPermission('View Cost')">Unit price</td>
                  <td>Quantity</td>
                  <td v-if="hasPermission('View Cost')">
                    Service total without vat
                  </td>
                  <td v-if="hasPermission('View Cost')">VAT</td>
                  <td v-if="hasPermission('View Cost')">
                    Service total with VAT
                  </td>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(item, idx) in details"
                  :key="`item.product_name${idx}`"
                  class="text-center"
                >
                  <td>
                    {{ item.service.name + "-" + item.subservice.name }}
                  </td>
                  <td v-if="hasPermission('View Cost')">
                    {{
                      parseFloat(String(item.total_price / item.qty)).toFixed(2)
                    }}
                  </td>
                  <td>{{ item.qty }}</td>
                  <td v-if="hasPermission('View Cost')">
                    {{ item.total_price }} SAR
                  </td>
                  <td v-if="hasPermission('View Cost')">{{ item.vat }}</td>
                  <!-- <td>{{ item.unit_price }} SAR X{{ item.qty }}</td> -->
                  <td v-if="hasPermission('View Cost')">
                    {{ (parseInt(item.total_price_vat)).toFixed(2) }} SAR
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <div v-if="order.pickup_option_price > 0">
              <h2>Pickup Cost :</h2>
            </div>
            <v-simple-table v-if="order.pickup_option_price > 0" class="my-4">
              <thead class="munjz-bg-grey text-center">
                <tr>
                  <td>Unit price</td>
                  <td>Quantity</td>
                  <td>vat</td>
                  <td>Total with VAT</td>
                </tr>
              </thead>

              <tbody>
                <tr class="text-center">
                  <td>Pickup cost</td>
                  <td>{{ order.pickup_option_price }}</td>
                  <td>1</td>
                  <td>{{ order.pickup_option_price_vat }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>

          <!-- {{services sale  total row}} -->
          <v-row v-if="hasPermission('View Cost')" class="ma-0">
            <v-col cols="12" class="d-flex justify-center">
              <table class="rounded-lg pa-3 munjz-bg-grey">
                <tr>
                  <td><b>Services without vat</b></td>
                  <td>{{ servicesWithoutVat }} SAR</td>
                </tr>
                <tr v-if="order.pickup_option_price > 0">
                  <td><b>Pickup price without vat </b></td>
                  <td>{{ order.pickup_option_price }} SAR</td>
                </tr>
                <tr>
                  <td><b>Vat</b></td>
                  <td>{{ order.final_total_vat }} SAR</td>
                </tr>
                <tr>
                  <td><b>Total</b></td>
                  <td>{{ order.final_total_price_with_vat }} SAR</td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </v-row>

        <v-row v-if="hasPermission('View Cost')" block class="ma-0">
          <v-col cols="12">
            <div class="my-4">
              <h2>Services Sale :</h2>
            </div>

            <v-simple-table class="rounded-lg my-4">
              <thead class="text-center">
                <tr class="munjz-bg-grey">
                  <td>Service type</td>
                  <td>Unit price</td>
                  <td>Quantity</td>
                  <td>Service total</td>
                  <td>Total with VAT</td>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(item, idx) in details"
                  :key="`item.product_name${idx}`"
                  class="text-center"
                >
                  <td>
                    {{ item.service.name + "-" + item.subservice.name }}
                  </td>
                  <td>{{ item.partner_sale_unit }}</td>
                  <td>{{ item.qty }}</td>
                  <td>{{ item.partner_total_sale }} SAR X{{ item.qty }}</td>
                  <td>{{ item.partner_total_sale_vat }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>

          <!-- {{services sale  total row}} -->
          <v-row v-if="hasPermission('View Cost')">
            <v-col cols="12" class="d-flex justify-center">
              <table class="rounded-lg pa-3 munjz-bg-grey">
                <tr>
                  <td><b>Subtotal</b></td>
                  <td>{{ order.partner_sale_unit }} SAR</td>
                </tr>
                <tr>
                  <td><b>Vat</b></td>
                  <td>{{ order.partner_total_sale_vat.toFixed(2) }} SAR</td>
                </tr>
                <tr>
                  <td><b>Total</b></td>
                  <td>{{ order.partner_total_sale.toFixed(2) }} SAR</td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </v-row>

        <!-- products table  -->

        <v-row v-if="products.length > 0" block class="ma-0">
          <v-col cols="12">
            <div class="my-4">
              <h2>Products :</h2>
            </div>

            <v-simple-table>
              <thead class="text-center munjz-bg-grey">
                <tr>
                  <td>Product Name</td>
                  <td>Quantity</td>
                  <td>Item number</td>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="product in products"
                  :key="product.id"
                  class="text-center"
                >
                  <td>{{ product.product_name }}</td>
                  <td>{{ product.quantity }}</td>
                  <td>{{ product.item_number }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-row class="ma-0">
          <v-col cols="12" class="d-flex justify-center">
            <v-btn
              @click="cancelDialog = true"
              color="red"
              v-if="order.status == 0"
              class="me-0 my-4"
              ><v-icon left>mdi-close</v-icon> Cancel Order</v-btn
            >
          </v-col>
        </v-row>
      </v-card>

      <v-snackbar
        class="rounded-pill"
        v-model="snackbar"
        timeout="3000"
        centered
        color="green"
      >
        Your order is Cancelled
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
/* eslint-disable */

export default {
  data() {
    return {
      please_wait_dialog: false,
      role: localStorage.getItem("role"),
      snackbar: false,
      merchant: {},
      order: {},
      branchDetail: [],
      products: [],
      details: [],
      token: localStorage.getItem("token"),
      user_id: localStorage.getItem("userid"),
      user_name: localStorage.getItem("name"),
      cancelLoading: false,
      cancelDialog: false,
      orderStateOptions: [
        {
          statusText: "Pending",
          color: "#aeb400"
        },
        {
          statusText: "Requested",
          color: "primary"
        },
        {
          statusText: "Confirmed",
          color: "teal"
        },
        { statusText: "On the Way", color: "primary" },
        {
          statusText: "In Progress",
          color: "orange"
        },
        {
          statusText: "Completed",
          color: "green"
        },
        {
          statusText: "Canceled",
          color: "red"
        }
      ]
    };
  },
  mounted() {
    this.getdetails();
  },
  computed: {
    servicesWithoutVat() {
      return this.order.final_total_price - this.order.pickup_option_price;
    }
  },
  methods: {
    getdetails() {
      this.please_wait_dialog = true;

      axios
        .get("/api/v3/b2b/get-order-details/" + this.$route.params.id, {
          headers: { "X-Authorization": this.token }
        })
        .then(res => {
          this.order = res.data;
          this.merchant = res.data.merchant;
          this.branchDetail = res.data.branch;
          this.details = res.data.details;
          this.products = res.data.products;
          this.please_wait_dialog = false;
        })
        .catch(err => console.log(err.response.data))
        .finally(() => (this.dialog = false));
    },
    CancelOrder() {
      this.cancelLoading = true;

      axios({
        method: "post", //you can set what request you want to be
        url: "/api/v3/b2b/update-order-status",
        data: {
          order_id: this.order.id,
          status: 6
        },
        headers: {
          "X-Authorization": this.token
        }
      })
        .then(res => {
          this.snackbar = true;
          this.cancelLoading = false;
          setTimeout(() => {
            this.$router.go(-1);
          }, 600);
        })
        .catch(err => console.log(err));
    }
  }
};
</script>
<style scoped>
/* .tab,
.head,
.li {
  border: 1px solid grey;
  padding: 7px;
  border-collapse: collapse;
  color: grey;
}

h1 {
  color: grey;
} */
</style>
